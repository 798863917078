import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ThcTvStyled } from "./ThcTv.styled"
import { UniversalLink } from "../../../utils/universalLink"
import Reveal, { Fade } from "react-awesome-reveal"
import { keyframes } from "@emotion/react";

export const ThcTv = ({ lazyLoading }) => {

  return (
    lazyLoading ? <ThcTvStyled>
      <Html />
    </ThcTvStyled> : <section className={"loading "}><Html /></section>
  )

}
const Html = () => {
  const data = useStaticQuery(graphql`
        query {
            wpPage(uri: {eq: "/"}) {
                home {
                    thcTv {
                        thcTvText
                        thcTvTitle
                        thcTvImg {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData 
                                }
                            }
                        }
                        thcTvButton {
                            target
                            title
                            url
                        }
                        thcTvStatus
                    }
                }
            }
        }
    `)
  const thcTv = data?.wpPage?.home?.thcTv
  
  const animeStyles = keyframes`
    from {
      opacity: 0;
      transform: translate3d(0, -200px, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `; 

  return (
    thcTv?.thcTvStatus && ( 
      <ThcTvStyled>
        <div className={"thctv-block"}>
          <div className={"container"}>
            <div className={"thc-tv-text"}>
              <Fade direction="left">
                <h2>{thcTv?.thcTvTitle}</h2>
              </Fade>
              <div dangerouslySetInnerHTML={{ __html: thcTv?.thcTvText}} />
              <Fade direction="up"><div>
              <UniversalLink to={thcTv?.thcTvButton?.url || "#"} className="btn btn--white"
                            target={thcTv?.thcTvButton?.target}>{thcTv?.thcTvButton?.title}
                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 16V2.22222V0L13 8L0 16Z" fill="black" />
                </svg>
              </UniversalLink>
              </div></Fade>
            </div>
            <div className={"thc-tv-img"}>
              <Reveal keyframes={animeStyles}><div>
              <GatsbyImage image={getImage(thcTv?.thcTvImg?.localFile?.childImageSharp?.gatsbyImageData)}
                          alt={thcTv?.thcTvImg?.altText || "banner"} />
              </div></Reveal>
            </div>
          </div>
        </div>
      </ThcTvStyled>
    )
  )
}
export default ThcTv
