import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { HeroStyled } from "./Hero.styled"
import { MainButton } from "../ui/main_button/MainButton"
import { Fade } from "react-awesome-reveal"

import ReactPlayer from "react-player/lazy"

import { useEffect, useState } from "react"

export const HeroHome = () => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(uri: { eq: "/" }) {
        home {
          overBanner {
            overBannerLocation1
            overBannerLocation2
            overBannerLocation3
            overBannerTitle
            overBannerLocationShowTop
            overBannerLocationShowBottom
          }
          banner {
            bannerBg {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
                # url
              }
              altText
            }
            bannerTitle
            bannerButton {
              target
              title
              url
            }
          }
        }
      }
    }
  `)
  const overBanner = data?.wpPage?.home?.overBanner
  const banner =
    data?.wpPage?.home?.banner?.bannerBg?.localFile?.childImageSharp
      ?.gatsbyImageData
  const bannerText = data?.wpPage?.home?.banner?.bannerBg?.altText
  const bannerTitle = data?.wpPage?.home?.banner?.bannerTitle
  const bannerButton = data?.wpPage?.home?.banner?.bannerButton

  const [playerStart, setPlayerStart] = useState(false)
  const [hideVideoPlaceHolder, SetHideVideoPlaceHolder] = useState(false)
  useEffect(() => {
    const t = setTimeout(() => {
      setPlayerStart(true)
    }, 1)
    return () => clearTimeout(t)
  }, [])
  return (
    <HeroStyled>
      <div className="container">
        <div className="header">
          { overBanner?.overBannerLocationShowTop && (
            <div className="address_parent">
              <Fade direction="left">
                <div>
                  <p className={"street"}>{overBanner?.overBannerLocation1}</p>
                  <p className={"street"}>{overBanner?.overBannerLocation2}</p>
                  <p className={"street"}>{overBanner?.overBannerLocation3}</p>
                </div>
              </Fade>
            </div>
          )}
          <Fade direction="right">
            <span>
              <h1>{overBanner?.overBannerTitle}</h1>
            </span>
          </Fade>
        </div>
        <div className="inner">
          <div
            style={{ opacity: hideVideoPlaceHolder ? 0 : 1 }}
            className={"poster"}
          >
            <GatsbyImage
              className="poster"
              image={getImage(banner)}
              alt={bannerText}
            />
          </div>

          <div className="video">
            {playerStart && (
              <ReactPlayer
                className="background"
                url="https://res.cloudinary.com/dy7uwojzq/video/upload/v1649959964/THC%20Vancouver/Y2Mate.is_-_THC_January_2021-yRVdrJXCvmM-1080p-1649959936174_tnioki.mp4"
                muted={true}
                loop={true}
                width="100%"
                height="100%"
                playing={true}
                onPlay={() => SetHideVideoPlaceHolder(true)}
              />
            )}
          </div>
          <div className="caption">
            <Fade direction="up">
              <div>
                <MainButton
                  url={bannerButton?.url}
                  target={bannerButton?.target}
                >
                  {bannerButton?.title}
                </MainButton>
              </div>
            </Fade>
            { overBanner?.overBannerLocationShowBottom && (
              <div className="address_parent">
                <Fade direction="up">
                  <div className="caption__address">
                    <p>{overBanner?.overBannerLocation1}</p>
                    <p>{overBanner?.overBannerLocation2}</p>
                    <p>{overBanner?.overBannerLocation3}</p>
                  </div>
                </Fade>
              </div>
            )}            
          </div>
        </div>
      </div>
    </HeroStyled>
  )
}
